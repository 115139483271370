<div class="wrap pos-rel">
  <h2 mat-dialog-title>{{ imageUrl.title }}</h2>
  <mat-dialog-content>
    @if (imageUrl.isIframe) {
    <iframe data-hj-allow-iframe="" [src]="imageUrl.url | safeWindow" frameborder="0"></iframe>
    } @if (!imageUrl.isIframe) {
    <img class="center-me max-w-100" [src]="imageUrl.url" alt="previw" />
    }
  </mat-dialog-content>
  <mat-dialog-actions>
    <button type="button" mat-raised-button mat-dialog-close class="btn btn-c">
      Close
    </button>
    <button type="button" mat-raised-button (click)="copyImage()" class="btn btn-b marg-left">
      copy link
    </button>
  </mat-dialog-actions>
</div>